import { Loader, LoaderOptions } from 'google-maps'

export default class GoogleMapsController {
  private static instance: GoogleMapsController;
  private loader: any
  private isSettingMaps = false

  public static getInstance (): GoogleMapsController {
    if (!GoogleMapsController.instance) {
      GoogleMapsController.instance = new GoogleMapsController()
      window.GoogleMapsController = GoogleMapsController.instance
    }

    return GoogleMapsController.instance
  }

  public async getGoogleMaps () {
    if (!this.loader && !this.isSettingMaps) {
      this.isSettingMaps = true
      try {
        const options: LoaderOptions = {/* todo */}
        const loader = new Loader(process.env.VUE_APP_GOOGLE_MAP_API_KEY, options)
        this.loader = await loader.load()
        this.isSettingMaps = false
      } catch (error) {
        // Silent
      }
    }

    return this.loader
  }
}
